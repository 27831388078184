@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: Gilroy, sans-serif;
    -webkit-tap-highlight-color: transparent;
    @apply text-base;
  }

  @font-face {
    font-family: "Gilroy";
    src: url("../fonts/gilroy/gilroy-bold.woff2") format("woff2"), url("../fonts/gilroy/gilroy-bold.woff") format("woff"), url("../fonts/gilroy/gilroy-bold.ttf") format("truetype"),
      url("../fonts/gilroy/gilroy-bold.svg#gilroy-bolduploaded_file") format("svg");
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: "Gilroy";
    src: url("../fonts/gilroy/gilroy-heavy.woff2") format("woff2"), url("../fonts/gilroy/gilroy-heavy.woff") format("woff"),
      url("../fonts/gilroy/gilroy-heavy.ttf") format("truetype"), url("../fonts/gilroy/gilroy-heavy.svg#gilroy-heavyuploaded_file") format("svg");
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: "Gilroy";
    src: url("../fonts/gilroy/gilroy-light.woff2") format("woff2"), url("../fonts/gilroy/gilroy-light.woff") format("woff"),
      url("../fonts/gilroy/gilroy-light.ttf") format("truetype"), url("../fonts/gilroy/gilroy-light.svg#gilroy-lightuploaded_file") format("svg");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "Gilroy";
    src: url("../fonts/gilroy/gilroy-medium.woff2") format("woff2"), url("../fonts/gilroy/gilroy-medium.woff") format("woff"),
      url("../fonts/gilroy/gilroy-medium.ttf") format("truetype"), url("../fonts/gilroy/gilroy-medium.svg#gilroy-mediumuploaded_file") format("svg");
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "Gilroy";
    src: url("../fonts/gilroy/gilroy-regular.woff2") format("woff2"), url("../fonts/gilroy/gilroy-regular.woff") format("woff"),
      url("../fonts/gilroy/gilroy-regular.ttf") format("truetype"), url("../fonts/gilroy/gilroy-regular.svg#gilroy-regularuploaded_file") format("svg");
    font-weight: normal;
    font-style: normal;
  }

  .content {
    @apply dark:text-white;
  }

  .content h1 {
    @apply text-4xl md:text-5xl font-bold leading-tight;
  }

  .content h2 {
    @apply text-xl md:text-2xl dark:font-bold;
  }

  .content h3 {
    @apply text-lg font-thin dark:font-semibold;
  }

  .content h4 {
    @apply font-thin dark:font-semibold;
  }

  .content h5 {
    @apply text-sm font-thin dark:font-semibold;
  }

  .content h6 {
    @apply text-xs font-thin dark:font-semibold;
  }

  .content p {
    @apply leading-6;
  }

  .content .lead p {
    @apply leading-7 text-lg;
  }

  .content figure {
    @apply w-full;
  }

  .content figcaption {
    @apply text-center text-xs py-1 p-2 bg-tv-gray-lightest italic;
  }

  .content img {
    @apply w-full border-8 border-tv-gray-light shadow-xl;
  }

  .content details>summary {
    list-style-type: "›   ";
    @apply w-full p-4 shadow cursor-pointer bg-tv-red text-white text-sm font-semibold uppercase tracking-wider;
  }

  .content details[open]>summary {
    list-style-type: "×   ";
  }

  .content details>* {
    @apply bg-tv-gray-lighter px-4;
  }

  .content details>p {
    @apply w-full py-4 mt-0 text-sm;
  }

  .content details[open]>*:not(summary) {
    animation: details-show 150ms ease-in-out;
  }

  @keyframes details-show {
    from {
      opacity: 0;
    }
  }

  .content details[open]>summary {
    /* @apply font-bold transition-all; */
  }

  /*.content a {
   @apply text-base font-bold text-tv-red-dark dark:text-white;
  }*/
  .content table {
    @apply w-full;
  }

  .content table td {
    @apply border border-tv-gray-lighter p-4 sm:p-6;
  }

  .content ul {
    @apply text-sm leading-6 list-disc list-inside;
  }

  .ckecontent h1,
  .ckecontent h2,
  .ckecontent h3,
  .ckecontent h4,
  .ckecontent h5,
  .ckecontent h6,
  .ckecontent p,
  .ckecontent figure,
  .ckecontent section,
  .ckecontent details,
  .ckecontent .lead {
    @apply mt-3 sm:mt-4 first:mt-0;
  }

  .ckecontent h2 {
    @apply mt-3 first:mt-0;
  }

  .ckecontent a {
    @apply text-base font-bold text-tv-red-dark dark:text-white;
  }

  .ckecontent-sticky a {
    @apply text-base font-bold text-white dark:text-white;
  }

  .generic-input {
    @apply border p-2 text-sm;
  }

  .generic-input-error {
    @apply border p-2 text-sm border-tv-red;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track-piece {
  background-color: #eeeeee;
}

::-webkit-scrollbar-thumb {
  background-color: #a0a0a0;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #787878;
}

.line-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.line-scrollbar::-webkit-scrollbar-track-piece {
  border-radius: 8px;
  background-color: rgba(255,255,255,0.15);
}

.line-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #d04335;
}

.line-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #d04335;
}

/* .resize-animation-stopper * {
  animation: none !important;
  transition: none !important;
} */

/* @screen sm { */
.in-top {
  transform-origin: 50% 0%;
  animation: in-top 300ms ease 0ms 1 forwards;
}

.in-right {
  transform-origin: 100% 0%;
  animation: in-right 300ms ease 0ms 1 forwards;
}

.in-bottom {
  transform-origin: 50% 100%;
  animation: in-bottom 300ms ease 0ms 1 forwards;
}

.in-left {
  transform-origin: 0% 0%;
  animation: in-left 300ms ease 0ms 1 forwards;
}

.out-top {
  transform-origin: 50% 0%;
  animation: out-top 300ms ease 0ms 1 forwards;
}

.out-right {
  transform-origin: 100% 50%;
  animation: out-right 300ms ease 0ms 1 forwards;
}

.out-bottom {
  transform-origin: 50% 100%;
  animation: out-bottom 300ms ease 0ms 1 forwards;
}

.out-left {
  transform-origin: 0% 0%;
  animation: out-left 300ms ease 0ms 1 forwards;
}

@keyframes in-top {
  from {
    transform: rotate3d(-1, 0, 0, 90deg);
  }

  to {
    transform: rotate3d(0, 0, 0, 0deg);
  }
}

@keyframes in-right {
  from {
    transform: rotate3d(0, -1, 0, 90deg);
  }

  to {
    transform: rotate3d(0, 0, 0, 0deg);
  }
}

@keyframes in-bottom {
  from {
    transform: rotate3d(1, 0, 0, 90deg);
  }

  to {
    transform: rotate3d(0, 0, 0, 0deg);
  }
}

@keyframes in-left {
  from {
    transform: rotate3d(0, 1, 0, 90deg);
  }

  to {
    transform: rotate3d(0, 0, 0, 0deg);
  }
}

@keyframes out-top {
  from {
    transform: rotate3d(0, 0, 0, 0deg);
  }

  to {
    transform: rotate3d(-1, 0, 0, 104deg);
  }
}

@keyframes out-right {
  from {
    transform: rotate3d(0, 0, 0, 0deg);
  }

  to {
    transform: rotate3d(0, -1, 0, 104deg);
  }
}

@keyframes out-bottom {
  from {
    transform: rotate3d(0, 0, 0, 0deg);
  }

  to {
    transform: rotate3d(1, 0, 0, 104deg);
  }
}

@keyframes out-left {
  from {
    transform: rotate3d(0, 0, 0, 0deg);
  }

  to {
    transform: rotate3d(0, 1, 0, 104deg);
  }
}

/* } */
.bg-fill-base {
  @apply bg-white dark:bg-tv-gray-darkest;
}

.text-color-base {
  @apply dark:text-white;
}

.btn {
  @apply bg-tv-red dark:bg-tv-purple hover:bg-tv-purple dark:hover:bg-white disabled:bg-tv-gray-dark transition rounded p-4 text-white dark:hover:text-tv-gray-darkest text-sm tracking-wide font-bold;
}